import Layout from "../../components/layout"
import React from "react"
import SEO from "../../components/seo"

function SchoolCalendar() {
  return (
    <Layout>
      <SEO title="Kalendarz szkolny - ZSP Nidek" />
      <div>
        <h1>Kalendarz szkolny</h1>
        <h2>Kalendarz roku szkolnego 2023/2024</h2>
        <ul>
          <li>
            <b>4 września 2023 r.</b> - uroczyste rozpoczęcie roku szkolnego
          </li>
          <li>
            <b>13 września 2023 r.</b> - wywiadówki
          </li>
          <li>
            <b>14 października 2023 r.</b> - Dzień Nauczyciela. Dzień Komisji
            Edukacji Narodowej.
          </li>
          <li>
            <b>1 listopada 2023 r.</b> - Wszystkich Świętych
          </li>
          <li>
            <b>8 listopada 2023 r.</b> - wywiadówki
          </li>
          <li>
            <b>11 listopada 2023 r.</b> - Narodowe Święto Niepodległości
          </li>
          <li>
            <b>23 grudnia 2023 r.</b> - dzień wolny od zajęć dydaktyczno - wychowawczych
          </li>
          <li>
            <b>24 grudnia 2023 r. - 1 stycznia 2024 r.</b> - zimowa przerwa świąteczna
          </li>
          <li>
            <b>2 stycznia 2024 r.</b> - dzień wolny od zajęć dydaktyczno - wychowawczych
          </li>
          <li>
            <b>6 stycznia 2024 r.</b> - Święto Trzech Króli
          </li>
          <li>
            <b>12 - 25 lutego 2024 r.</b> - ferie zimowe
          </li>
          <li>
            <b>28 marca 2024 r. - 2 kwietnia 2024 r.</b> - wiosenna przerwa świąteczna
          </li>
          <li>
            <b>1 maja 2024 r.</b> - Święto Pracy
          </li>
          <li>
            <b>2 maja 2024 r.</b> - dzień wolny od zajęć dydaktyczno -
            wychowawczych
          </li>
          <li>
            <b>3 maja 2024 r.</b> - Święto Narodowe Konstytucji Trzeciego Maja
          </li>
          <li>
            <b>14, 15, 16 maja 2024 r.</b> - egzamin ósmoklasisty (dni wolne od
            zajęć dydaktyczno - wychowawczych)
          </li>
          <li>
            <b>30 maja 2024 r.</b> - Boże Ciało
          </li>
          <li>
            <b>31 maja 2024 r.</b> - dzień wolny od zajęć dydaktyczno -
            wychowawczych
          </li>
          <li>
            <b>22 czerwca 2024 r.</b> - dzień wolny od zajęć dydaktyczno - wychowawczych
          </li>
          <li>
            <b>23 czerwca 2024 r.</b> - uroczyste zakończenie roku szkolnego
          </li>
        </ul>
        <p>
          Terminy wywiadówek i dni otwartych dla rodziców będą podawane na
          bieżąco poprzez dziennik elektroniczny.
        </p>
      </div>
    </Layout>
  )
}

export default SchoolCalendar
